// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-coach-parents-js": () => import("./../../../src/pages/coach-parents.js" /* webpackChunkName: "component---src-pages-coach-parents-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pga-of-america-california-privacy-notice-js": () => import("./../../../src/pages/pga-of-america/california-privacy-notice.js" /* webpackChunkName: "component---src-pages-pga-of-america-california-privacy-notice-js" */),
  "component---src-pages-pga-of-america-do-not-sell-js": () => import("./../../../src/pages/pga-of-america/do-not-sell.js" /* webpackChunkName: "component---src-pages-pga-of-america-do-not-sell-js" */),
  "component---src-pages-pga-of-america-terms-of-service-js": () => import("./../../../src/pages/pga-of-america/terms-of-service.js" /* webpackChunkName: "component---src-pages-pga-of-america-terms-of-service-js" */),
  "component---src-pages-training-js": () => import("./../../../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */)
}

